$(function(){
  $('.switch-lang-link-my, .switch-lang-link').on('click', function(event) {
      event.preventDefault();
      return switchLanguageMY( $(this).attr('data-mp-lang') );
  });

  function switchLanguageMY(lang) {
      var domains = { 'en_MY' : '/', 'ms_MY' : '/ms-e-MY/' };
      if (lang != generic.cookie('LOCALE')){
          var path = window.location.pathname.replace(/\/ms\-e-MY/, "");
          document.cookie = 'LOCALE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          generic.cookie('LOCALE', lang, {path : '/'});

          if (path.indexOf(".tmpl") > 0 || path.indexOf(".tmpl") > 0) {
            domains[lang] = '';
          }
          try {
            if (eval("Drupal.settings.elc_common." + lang + "_url") != undefined && path != "/") {
              path = eval("Drupal.settings.elc_common." + lang + "_url");
            }
          }
          catch (error) {}

          if (path == "/") {
            path = "";
          }

          var url = window.location.protocol + '//' + window.location.host + domains[lang] + path;
          window.location.href = url;
      }

  }

  $(document).ready(function () {
    var path = window.location.pathname;
    var pathArray = path.split('/');
    if (generic.cookie('LOCALE') == 'ms_MY' && pathArray[1] != 'ms-e-MY' && path.indexOf(".tmpl") == -1 && path.indexOf(".tmpl") == -1) {
      window.location.href = window.location.protocol + '//' + window.location.host + '/ms-e-MY' + window.location.pathname;
    }
  });
});
